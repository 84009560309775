import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from '../components/Section'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section title="404: Not Found" size="sm">
      <p>Oops! Looks like there's nothing here.</p>
      <p>
        <a href="/"><FA icon={faHome} /> Go Home</a>
      </p>
    </Section>
  </Layout>
)

export default NotFoundPage
